<template>
	<div class="menu-item-style">
		<h5 class="menu-item-type">{{title}}</h5>
		<ul>
			<li v-for="(item, index) in menuList" :key="index">
				<div class="menu-item-title">
					<i :class="['el-icon-color',item.icon || 'el-icon-menu']"></i>
					<span>{{ item.name }}</span>
				</div>
				<div class="menu-item-handel">
					<i class="el-icon-edit" @click="editMenu(item)"></i>
					<i class="el-icon-delete" @click="delMenu(item, 0)"></i>
					<i v-if="showBtnHandle" class="el-icon-s-unfold" @click="handleBtn(item,0)"></i>
					<i v-if="showToSubHandle" class="el-icon-right" @click="toSubMenu(item)"></i>
				</div>
			</li>
		</ul>
		<div class="menu-btn"><el-button icon="el-icon-plus" type="primary" size="small" plain @click="addMenu">添加</el-button></div>
	</div>
</template>

<script>
	export default{
		name:"RightItem",
		props: {
			menuList:{
				type:Array,
				default:()=>{}
			},
			title:{
				type:String,
				default:"菜单列表"
			},
			showBtnHandle:{
				type:[Boolean,String],
				default:true
			},
			showToSubHandle:{
				type:[Boolean,String],
				default:true
			},
			itemIndex:{
				type:Number,
				default:0
			}
		},
		methods:{
			editMenu(item){
				this.$emit("editMenu",item)
			},
			delMenu(item){
				this.$emit("delMenu",[item,this.itemIndex])
			},
			handleBtn(item){
				this.$emit("handleBtn",[item,this.itemIndex])
			},
			toSubMenu(item){
				this.$emit("toSubMenu",item)
			},
			addMenu(){
				this.$emit("addMenu",this.itemIndex)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.menu-item-style {
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		.menu-item-type {
			color: #333;
			font-size: 16px;
			font-weight: bold;
			width: 100%;
			text-align: center;
			margin: 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding: 6px 0;
		}
		ul {
			height: 600px;
			min-width: 300px;
			padding: 16px 26px 34px 26px;
			box-sizing: border-box;
			overflow-y: auto;
		}
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			list-style: none;
			color: #444;
			font-size: 16px;
			box-sizing: border-box;
			padding: 12px 0;
			border-bottom: 1px solid #f0f0f0;
			.el-icon-color {
				color: #67c23a;
				font-size: 12px;
				margin-right: 8px;
			}
			span {
				margin-right: 30px;
			}
			.menu-item-title {
				display: flex;
				align-items: center;
				span{
					font-size: 16px;
					font-weight: bold;
					color: #666;
				}
			}
			.menu-item-handel {
				i {
					margin-left: 20px;
					cursor: pointer;
					font-size: 16px;
				}
				.el-icon-edit {
					color: #909399;
				}
				.el-icon-delete {
					color: #f56c6c;
				}
			}
		}
	}
	.menu-btn {
		padding: 6px 0;
		text-align: center;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		background-color: #fff;
	}
	.btn-area {
		display: flex;
		flex-wrap: wrap;
		.btn-area-item {
			position: relative;
			box-sizing: border-box;
			padding: 0;
			height: 32px;
			margin-right: 14px;
			margin-bottom: 14px;
			.del {
				box-sizing: border-box;
				position: absolute;
				text-align: center;
				z-index: 9;
				top: -6px;
				right: -4px;
				background-color: #f56c6c;
				width: 12px;
				height: 12px;
				padding: 0;
				line-height: 8px;
				font-size: 2px;
				color: #fff;
				border-radius: 50%;
				cursor: pointer;
			}
		}
	}
	.btn-tips-wran{
		color: #fa5151;
		padding: 10px 0 0 0;
	}
	.btn-dialog{
		margin-top: 40px;
	}
</style>
